
import {
  IonModal,
  IonIcon,
  IonFab,
  IonFabButton,
  toastController,
  alertController,
} from '@ionic/vue'
import {useRouter} from 'vue-router';
import {defineComponent, ref} from "vue";
import ApiService from "@/services/api.service";
import {chevronForwardOutline, sendOutline, add} from 'ionicons/icons';
import InputGroup from "@/components/InputGroup.vue";
import Button from "@/components/Button.vue";
import Container from "@/views/layout/Container.vue";
import moment from "moment";
import {useI18n} from "vue-i18n";
import Modal from "@/components/Modal.vue";
import Upload from "@/components/Upload.vue";
import SelectRecipients from "@/components/SelectRecipients.vue";
import List from "@/components/messages/List.vue";
import mixin from "@/mixin";
import TabBar from "@/components/patients/TabBar.vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: 'Messages',
  components: {
    TabBar,
    List,
    SelectRecipients,
    Upload,
    Container,
    InputGroup,
    Button,
    IonModal,
    Modal,
    IonIcon,
    IonFab,
    IonFabButton,
  },

  setup() {
    const router = useRouter();
    const {t} = useI18n()

    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;

    const uploadRef = ref(Upload);

    return {
      t,
      router,
      isOpenRef,
      setOpen,
      chevronForwardOutline,
      sendOutline,
      moment,
      add,
      uploadRef
    };
  },

  data() {
    return {
      records: [],
      subject: '',
      message: '',
      recipients: [],
      isPrivate: true,
      sent: false,
      loading: false,
      backButton: [] as any[],
    };
  },

  mixins: [
    mixin
  ],

  computed: {
    ...mapGetters("permission", ["getPermission"]),
    ...mapGetters({
      hasMainAccount: 'auth/hasMainAccount',
    }),
    isProfessional(): boolean {
      return this.getPermission('user.type')?.toLowerCase() === 'professional';
    },
    isChild(): boolean {
      return this.getPermission('user.isChild');
    }
  },

  methods: {
    ...mapActions("permission", ["fetchPermission"]),

    ionViewWillEnter() {
      if (this.$route.params.id && !this.isOpenRef) {
        this.setOpen(true);
      }

      this.getMessages();
    },

    ionViewWillLeave() {
      //this.backButton.forEach(b => b());
    },

    async getMessages() {
      this.loading = true;
      await ApiService.get('/message' + (this.sent ? '/sent' : '') + (this.hasMainAccount ? '?guardian=1' : '')).then(({data}) => {
        this.records = data;
      })
      this.loading = false;
    },

    setSent(sent: boolean) {
      this.sent = sent;
      this.getMessages();
    },

    setRecipients(recipients: []) {
      this.recipients = recipients;
    },

    async sendMessage() {
      if (this.recipients.length < 1) {
        const toast = await toastController.create({
          message: this.t('noRecipients'),
          duration: 3000,
        });

        await toast.present();
        return;
      }

      ApiService.post('/message', {
        subject: this.subject,
        message: this.message,
        recipients: this.recipients,
        'is_private': this.isPrivate,
        upload_identifiers: this.uploadRef.completedUploads.map((object: any) => {
          return object.fileId;
        }),
      }).then(() => {
        this.subject = '';
        this.message = '';
        this.recipients = [];
        this.uploadRef.completedUploads = [];
        this.getMessages();
        this.setOpen(false);
      })
    },

    dismissModal() {
      this.setOpen(false);

      if (this.$route.name !== 'messages') {
        this.$router.replace('/team');
      }
    }
  },
});
