import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_IonTabButton = _resolveComponent("IonTabButton")!
  const _component_IonTabBar = _resolveComponent("IonTabBar")!

  return (_openBlock(), _createBlock(_component_IonTabBar, null, {
    default: _withCtx(() => [
      _createVNode(_component_IonTabButton, {
        tab: "patients",
        href: "/patients"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IonIcon, { icon: _ctx.peopleOutline }, null, 8, ["icon"]),
          _createVNode(_component_IonLabel, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('patients.title')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.getPermission('View_messages'))
        ? (_openBlock(), _createBlock(_component_IonTabButton, {
            key: 0,
            tab: "messages",
            href: "/messages"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IonIcon, { icon: _ctx.chatbubbleOutline }, null, 8, ["icon"]),
              _createVNode(_component_IonLabel, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('messages.title')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.getPermission('View_my_media'))
        ? (_openBlock(), _createBlock(_component_IonTabButton, {
            key: 1,
            tab: "media",
            href: "/media"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IonIcon, { icon: _ctx.imageOutline }, null, 8, ["icon"]),
              _createVNode(_component_IonLabel, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('media.title')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}