
import {
  IonList,
  IonItem,
  IonItemSliding,
  IonBadge,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonCard,
  IonCardContent
} from '@ionic/vue'
import {useRouter} from 'vue-router';
import {defineComponent} from "vue";
import {chevronForwardOutline, sendOutline, add} from 'ionicons/icons';
import moment from "moment";
import {useI18n} from "vue-i18n";
import Loading from "@/components/Loading.vue";
import {mapGetters} from "vuex";
import EmptyState from "@/components/EmptyState.vue";

export default defineComponent({
  name: 'List',
  components: {
    EmptyState,
    Loading,
    IonList,
    IonItem,
    IonItemSliding,
    IonBadge,
    IonLabel,
    IonTabBar,
    IonTabButton,
    IonCard,
    IonCardContent
  },
  props: {
    records: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    isInMessageView: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const {t} = useI18n();

    return {
      t,
      router,
      chevronForwardOutline,
      sendOutline,
      moment,
      add,
    };
  },
  data() {
    return {
      sent: false,
    }
  },
  computed: {
    ...mapGetters({
      locale: 'app/locale',
    }),
  },
  methods: {
    setSent(sent: boolean) {
      this.sent = sent;

      this.$emit('changeSent', sent);
    },

    openExercise(id: number) {
      if (this.isInMessageView) {
        return this.router.replace(`/messages/${id}`);
      }

      this.router.push(`/messages/${id}`)
    },
  }
});
