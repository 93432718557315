
import {defineComponent} from "vue";
import {
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/vue';
import { peopleOutline, chatbubbleOutline, imageOutline } from 'ionicons/icons';
import {useI18n} from "vue-i18n";
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'TabBar',
  components: {
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
  },
  computed: {
    ...mapGetters("permission", ["getPermission"]),
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      peopleOutline,
      chatbubbleOutline,
      imageOutline,
    };
  }
});
